import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56')
];

export const server_loads = [0];

export const dictionary = {
		"/(index)": [~15],
		"/aboutme": [24],
		"/(misc)/about": [16],
		"/achievements": [25],
		"/achievements/[id]": [26],
		"/arena": [~27],
		"/callback": [28],
		"/contactus": [29],
		"/create": [30],
		"/(trivia)/customgame": [20],
		"/daily-quest": [~31],
		"/(auth)/delete": [6],
		"/(dev)/dev": [~12],
		"/(dev)/dev/geo": [~13],
		"/(trivia)/duel": [~21],
		"/(misc)/faq": [17],
		"/(auth)/forgot-password": [7],
		"/friends": [~32,[3]],
		"/friends/pending": [~33,[3]],
		"/friends/requests": [~34,[3]],
		"/friends/suggestions": [~35,[3]],
		"/gr33n": [36,[4]],
		"/gr33n/2": [37,[4]],
		"/gr33n/contact": [38,[4]],
		"/gr33n/merch": [39,[4]],
		"/gr33n/music": [40,[4]],
		"/leaderboards": [~41],
		"/leaderboards/friends": [~42],
		"/placement": [~43],
		"/(misc)/privacy": [18],
		"/(auth)/reset-password": [8],
		"/search": [~44],
		"/search/[query]": [~45],
		"/shop": [~46],
		"/shop/cancel": [47],
		"/shop/success": [~48],
		"/(auth)/signin": [~9],
		"/(auth)/signout": [~10],
		"/(auth)/signup": [~11],
		"/social/friends": [49,[5]],
		"/social/last_match": [50,[5]],
		"/social/recent_players": [51,[5]],
		"/(misc)/terms": [19],
		"/(dev)/test/img": [14],
		"/tree": [52],
		"/(trivia)/trivia/train": [~22,[2]],
		"/users": [~56],
		"/user": [~53],
		"/user/[id]": [~54],
		"/user/[id]/update": [55],
		"/(trivia)/world": [23]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';